import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { data } from "../../productDetail/data";
export default function ViewProduct() {
  const [click, setClick] = useState(true);
  const [click1, setClick1] = useState(true);
  const [searchParams] = useSearchParams();
  const id: any = searchParams.get("id");
  const [product, setProduct]: any = useState();
  const [selectedImage, setSelectedImage]: any = useState("");
  useEffect(() => {
    if (id) {
      let productIndex: any = parseInt(id) - 1;
      setProduct(data[productIndex]);
    }
  }, [id]);

  useEffect(() => {
    if (product?.moreImage && product.moreImage.length > 0) {
      setSelectedImage(product.moreImage[0]);
    }
  }, [product]);

  const handleThumbnailClick = (newImage: any) => {
    setSelectedImage(newImage);
  };
  return (
    <main>
      <section className="bg-white text-[#25485a] relative py-4 md:py-[6.5rem]">
        <div className="container mx-auto px-[5vw]">
          <div className="grid grid-cols-12 gap-2">
            <div className="col-span-12 md:col-span-2 md:px-3 px-1">
              <div className="flex justify-between md:block">
                {product?.moreImage?.map((item: any, index: number) => (
                  <div
                    key={index}
                    className={`h-[80px] w-[80px] mb-1 border-2 border-[#25485a] rounded-lg ${selectedImage === item ? 'border-4' : 'border-2'}`}
                    onClick={() => handleThumbnailClick(item)}
                  >
                    <img
                      src={item}
                      alt=""
                      className="h-full w-full rounded-lg"
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="col-span-12 md:col-span-3 md:px-3 px-1">
              <div className="product-visuals text-center relative ">
                <div
                  id="product-visuals-slider"
                  className="product-visuals-slider swiper"
                  is="visualsSlider"
                >
                  <div className="product-visuals-wrapper swiper-wrapper">
                    <div className="product-visuals-slide swiper-slide sm:h-[300px] sm:w-[300px] md:h-auto md:w-auto m-auto">
                      <img
                        loading="lazy"
                        src={selectedImage}
                        className="img-fluid img-product  object-cover w-full h-full "
                        alt="Chronis RTS - 1805040 - 1 - Decode"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-7 md:ms-[8.3333333333%] md:px-3 px-1 relative product-info">
              <h1 className="product-title flex items-center mb-0 md:mb-4 min-h-[3.25rem] font-medium text-2xl  md:text-4xl leading-[105%]">
                {product?.name}
              </h1>

              <div className="product-actions actions product-wrapper">
                <div className="product-btns flex md:gap-2 gap-1 flex-wrap items-center md:mb-6 mb-4">
                  <Link
                    to="/contactUs"
                    className="md:mt-5 mt-1 text-[#25485a]  bg-[#ffb71e] pt-[0.4375rem] pb-[0.5625rem] px-4 md:px-6 relative inline-flex items-center justify-center align-middle text-center  font-medium md:text-base text-sm leading-6 rounded-[20px] no-underline whitespace-no-wrap max-w-full"
                    style={{ boxShadow: "0 0 0 2px rgba(227,219,209,.5)" }}
                  >
                    Request quote
                  </Link>
                </div>
              </div>

              <div className="product-description md:mb-6 mb-2">
                <p className="text-sm leading-[125%] mt-0 mb-4">
                  {product?.content}
                </p>

                <a
                  className="-link text-[#016b65]"
                  style={{
                    backgroundSize: "0 0.0625rem",
                    backgroundPositionX: "right",
                    transition:
                      "all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95),background-position 0s",
                    background:
                      "linear-gradient(0deg, #016B65, #016B65) no-repeat left bottom/100% 0.0625rem",
                  }}
                  href="#specifications"
                >
                  Find out more
                </a>
              </div>

              <div className="product-compatibility md:pt-6 pt-4 border-t-2 border-t-[#e5e5e5]">
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-7">
                  {product?.feature?.map((item: any, index: any) => {
                    return (
                      <div className="specificate text-center">
                        <div className="assemble ">
                          <img
                            src={item?.image}
                            alt=""
                            className=" h-[60px] w-[60px] m-auto mb-2"
                          />
                          <div className="content m-auto">
                            <span className="font-[500] text-center text-xs leading-3">
                              {item?.type}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="specifications">
        <section className="bg-[#dad3b7] py-4 md:py-[5.5rem] relative text-[#25485a]">
          <div className="container mb-5 mx-auto px-[8vw]">
            <div className="grid grid-cols-12 -mx-3">
              <div className="col-span-12 md:col-span-10 px-3 mt-16">
                <h2 className="text-content-accordion-title  mb-2 font-medium text-2xl md:text-4xl">
                  Benefits
                </h2>
              </div>
            </div>
            <div className="grid grid-cols-12 -mx-3 md:mb-5">
              <div className="col-span-12 md:col-span-10 px-3">
                <div className="benefits md:mt-5 mb-0 p-0">
                  <p className="mt-0 md:mb-4 mb-0 text-sm md:text-base">
                    {product?.benefits?.map((item: any, index: any) => {
                      return (
                        <span key={index}>
                          <strong>{item?.title}</strong>
                          <br />- {item?.description}
                          <br />
                          <br />
                        </span>
                      );
                    })}
                  </p>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 -mx-3">
              <div className="col-span-12 md:col-span-10 px-3">
                <h2 className="text-content-accordion-title  mb-2 font-medium text-2xl md:text-4xl">
                  Details & specifications
                </h2>
              </div>
            </div>
            <div className="grid grid-cols-12 -mx-3">
              <div className="col-span-12 md:col-span-8 px-3">
                <div className="w-full mx-auto">
                  <div className="accordion mb-4 border-b-2 border-b-[#2b403b]  transition-all">
                    <div className="accordion-item">
                      <div
                        className="accordion-header flex items-center justify-between font-normal text-lg leading-6 py-[1.25rem]  ps-6 pe-12 "
                        onClick={() => {
                          setClick(!click);
                        }}
                      >
                        <p className="md:text-lg text-base font-semibold">
                          Description
                        </p>
                        <i className="fa-solid fa-angle-down cursor-pointer"></i>
                      </div>

                      <div
                        className={`accordion-content transition-all p-5  ${
                          click ? "block" : "hidden"
                        }`}
                      >
                        <p>{product?.details?.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full mx-auto">
                  <div className="accordion mb-4 border-b-2 border-b-[#2b403b] transition-all">
                    <div className="accordion-item">
                      <div
                        className="accordion-header flex items-center justify-between font-normal text-lg leading-6 py-[1.25rem]  ps-6 pe-12 "
                        onClick={() => {
                          setClick1(!click1);
                        }}
                      >
                        <p className="md:text-lg text-base font-semibold">
                          Dimension and Weight
                        </p>
                        <i className="fa-solid fa-angle-down cursor-pointer"></i>
                      </div>

                      <div
                        className={`accordion-content transition-all p-5  ${
                          click1 ? "block" : "hidden"
                        }`}
                      >
                        <p>
                          Width: {product?.details?.dimention?.width}
                          <br /> Height : {product?.details?.dimention?.height}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="compatibility-v2 my-8 md:my-14 lg:my-30">
        <div className="container mx-auto">
          <div className="mx-auto  px-[8vw] ">
            <h2 className="text-content-accordion-title mb-8 lg:mb-16 font-medium text-xl md:text-4xl text-[#25485a]">
              Applications
            </h2>
            <div className=" px-3">
              <div className="grid grid-cols-2 md:grid-cols-5 lg:grid-cols-5 gap-4">
                {product?.application?.map((item: any, index: any) => {
                  return (
                    <div className="specificate text-center">
                      <div className="assemble ">
                        <img
                          src={item?.image}
                          alt=""
                          className=" h-[80px] w-[80px] m-auto mb-2"
                        />
                        <div className="content m-auto">
                          <span className="font-[500] text-center text-sm">
                            {item?.type}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="compatibility-v1 my-8 md:my-14 lg:my-30">
        <div className="container mx-auto">
          <div className="mx-auto  px-[8vw] ">
            <h2 className="text-content-accordion-title mb-8 lg:mb-16 font-medium text-xl md:text-4xl text-[#25485a]">
              Industries
            </h2>
            <div className="px-3">
              <div className="grid grid-cols-2 md:grid-cols-5 lg:grid-cols-9 gap-4">
                {product?.industries?.map((item: any, index: any) => {
                  return (
                    <div className="specificate text-center">
                      <div className="assemble ">
                        <img
                          src={item?.image}
                          alt=""
                          className=" h-[80px] w-[80px] m-auto mb-2"
                        />
                        <div className="content m-auto">
                          <span className="font-[500] text-center text-sm">
                            {item?.type}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      {product?.specification?.ratedTorque ? (
        <section className="bg-[#dad3b7] md:pt-12 text-[#2b403b] relative md:py-24 py-12 pt-6">
          <div className="container mx-auto mb-10 px-[8vw]">
            <div className="grid grid-cols-12 -mx-3 ">
              <div className="col-span-12 px-3">
                <h2 className="md:mb-14 mb-5 font-medium md:text-5xl text-xl">
                  Technical characteristics
                </h2>

                <div className="collapse-wrapper">
                  <div
                    className="wysiwyg collapse-content"
                    id="collapseExample-3"
                  >
                    <table className="w-full mt-8">
                      <tbody>
                        <tr>
                          <td className="px-4 py-2 border-b-2  text-sm md:text-xl  border-b-[#2b403b]">
                            <strong className="t-16">Rated Torque</strong>
                          </td>
                          <td className="t-16 px-4 py-2 border-b-2  text-sm md:text-xl  border-b-[#2b403b]">
                            {product?.specification?.ratedTorque}
                          </td>
                        </tr>

                        <tr>
                          <td className="px-4 py-2 border-b-2  text-sm md:text-xl  border-b-[#2b403b]">
                            <strong className="t-16">Rated Speed</strong>
                          </td>
                          <td className="t-16 px-4 py-2 border-b-2  text-sm md:text-xl  border-b-[#2b403b]">
                            {product?.specification?.ratedSpeed}
                          </td>
                        </tr>

                        <tr>
                          <td className="px-4 py-2 border-b-2  text-sm md:text-xl  border-b-[#2b403b]">
                            <strong className="t-16">
                              Power Supply Waltage
                            </strong>
                          </td>
                          <td className="t-16 px-4 py-2 border-b-2  text-sm md:text-xl  border-b-[#2b403b]">
                            {product?.specification?.powerSupplyVoltage}
                          </td>
                        </tr>

                        <tr>
                          <td className="px-4 py-2 border-b-2  text-sm md:text-xl  border-b-[#2b403b]">
                            <strong className="t-16">Motor Power</strong>
                          </td>
                          <td className="t-16 px-4 py-2 border-b-2  text-sm md:text-xl  border-b-[#2b403b]">
                            {product?.specification?.motorPower}
                          </td>
                        </tr>

                        <tr>
                          <td className="px-4 py-2 border-b-2  text-sm md:text-xl border-b-[#2b403b]">
                            <strong className="t-16">Rated Current</strong>
                          </td>
                          <td className="t-16 px-4 py-2 border-b-2  text-sm md:text-xl border-b-[#2b403b]">
                            {product?.specification?.ratedCurrent}
                          </td>
                        </tr>

                        <tr>
                          <td className="px-4 py-2 border-b-2  text-sm md:text-xl border-b-[#2b403b]">
                            <strong className="t-16">Motor Diameter </strong>
                          </td>
                          <td className="t-16 px-4 py-2 border-b-2 text-sm md:text-xl  border-b-[#2b403b]">
                            {product?.specification?.motorDiameter}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-4 py-2 border-b-2  text-sm md:text-xl border-b-[#2b403b]">
                            <strong className="t-16">Running Time</strong>
                          </td>
                          <td className="t-16 px-4 py-2 border-b-2 text-sm md:text-xl  border-b-[#2b403b]">
                            {product?.specification?.runningTime}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-4 py-2 border-b-2  text-sm md:text-xl border-b-[#2b403b]">
                            <strong className="t-16">Max Load</strong>
                          </td>
                          <td className="t-16 px-4 py-2 border-b-2 text-sm md:text-xl  border-b-[#2b403b]">
                            {product?.specification?.maxLoad}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-4 py-2 border-b-2  text-sm md:text-xl border-b-[#2b403b]">
                            <strong className="t-16">Protection Leavel</strong>
                          </td>
                          <td className="t-16 px-4 py-2 border-b-2 text-sm md:text-xl  border-b-[#2b403b]">
                            {product?.specification?.protectionLeavel}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="container mx-auto mb-10 px-[5vw] pt-12 pb-16">
          <div className="grid grid-cols-1">
            <div className="col-12 col-10@md col-span-12 md:col-span-10">
              <div className="row row-head fade-up fade-up-active">
                <div className="col-12 col-8@md">
                  <h2 className="row-head-title h3"> Documents and links </h2>
                </div>
              </div>
              <div className="grid grid-cols-1">
                <ul className="downloads-list -bg-white  col-12">
                  <li>
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      className="svg-icon"
                    ></svg>
                    <span className="t-18">
                      Telis 6 Chronis RTS Installation guide PT EL SV DA FI NO
                    </span>
                    <a
                      href="https://asset.somfy.com/Document/8923dcca-4e5e-4aa9-9278-558ae459d084_leaf_telis_6_chronis_rts_5065944A_IG.pdf"
                      target="_blank"
                      className="tc_event_download"
                      data-filename="leaf_telis_6_chronis_rts_5065944A_IG.pdf"
                      data-label="download"
                    >
                      <span className="t-16 -link">Download</span>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        className="svg-icon"
                      ></svg>
                    </a>
                  </li>

                  <li>
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      className="svg-icon"
                    ></svg>
                    <span className="t-18">
                      Telis 6 Chronis RTS Installation guide FR DE TR ZH EN AR
                    </span>
                    <a
                      href="https://asset.somfy.com/Document/ffe2329a-cc5d-493e-b9dd-37894d9e1744_leaf_telis_6_chronis_rts_5065945A_IG.pdf.pdf"
                      target="_blank"
                      className="tc_event_download"
                      data-filename="leaf_telis_6_chronis_rts_5065945A_IG.pdf.pdf"
                      data-label="download"
                    >
                      <span className="t-16 -link">Download</span>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        className="svg-icon"
                      ></svg>
                    </a>
                  </li>

                  <li>
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      className="svg-icon"
                    ></svg>
                    <span className="t-18">
                      Telis 6 Chronis RTS Installation guide FR DE IT NL EN ES
                    </span>
                    <a
                      href="https://asset.somfy.com/Document/54880211-1bcf-479a-8ff5-baf62ce6bec0_leaf_telis_6_chronis_rts_5065943A_IG.pdf"
                      target="_blank"
                      className="tc_event_download"
                      data-filename="leaf_telis_6_chronis_rts_5065943A_IG.pdf"
                      data-label="download"
                    >
                      <span className="t-16 -link">Download</span>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        className="svg-icon"
                      ></svg>
                    </a>
                  </li>

                  <li>
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      className="svg-icon"
                    ></svg>
                    <span className="t-18">
                      5065943B_Telis_6_Chronis RTS.pdf.pdf
                    </span>
                    <a
                      href="https://asset.somfy.com/Document/bd55c5eb-ceae-4e98-9a98-569658c5afeb_5065943B_Telis_6_Chronis%20RTS.pdf.pdf"
                      target="_blank"
                      className="tc_event_download"
                      data-filename="5065943B_Telis_6_Chronis RTS.pdf.pdf"
                      data-label="download"
                    >
                      <span className="t-16 -link">Download</span>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        className="svg-icon"
                      ></svg>
                    </a>
                  </li>

                  <li>
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      className="svg-icon"
                    ></svg>
                    <span className="t-18">
                      Telis 6 Chronis RTS Installation guide PL RO CS HU RU HR
                    </span>
                    <a
                      href="https://asset.somfy.com/Document/0138d48c-f7e0-47d4-bd9b-9fdaace446ba_leaf_telis_6_chronis_rts_5065946A_IG.pdf"
                      target="_blank"
                      className="tc_event_download"
                      data-filename="leaf_telis_6_chronis_rts_5065946A_IG.pdf"
                      data-label="download"
                    >
                      <span className="t-16 -link">Download</span>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        className="svg-icon"
                      ></svg>
                    </a>
                  </li>

                  <li>
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      className="svg-icon"
                    ></svg>
                    <span className="t-18">
                      5065944B_Telis_6_Chronis RTS.pdf.pdf
                    </span>
                    <a
                      href="https://asset.somfy.com/Document/d16a1f44-1e4d-4d5d-9566-c49ca5a64047_5065944B_Telis_6_Chronis%20RTS.pdf.pdf"
                      target="_blank"
                      className="tc_event_download"
                      data-filename="5065944B_Telis_6_Chronis RTS.pdf.pdf"
                      data-label="download"
                    >
                      <span className="t-16 -link">Download</span>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        className="svg-icon"
                      ></svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div> */}
        </section>
      ) : null}
      <section className="compatibility-v3 my-8 md:my-14 lg:my-30">
        <div className="container mx-auto">
          <div className="mx-auto  px-[8vw] ">
            <h2 className="text-content-accordion-title mb-8 lg:mb-16 font-medium text-xl md:text-4xl text-[#25485a]">
              Certification
            </h2>
            <div className="px-3">
              <div className="grid grid-cols-2 md:grid-cols-5 lg:grid-cols-10 gap-4">
                {product?.certification?.map((item: any, index: any) => {
                  return (
                    <div className="specificate text-center" key={index}>
                      <div className="assemble ">
                        <img src={item} alt="" className="  m-auto mb-2" />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
