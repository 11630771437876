import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

const Contactus = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFormData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    country: "",
    zipcode: "",
    phone: "",
    message: "",
    city: "",
    range: {
      control: false,
      motor: false,
      sensor: false,
    },
  });
  const form: any = useRef();
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    window.scrollTo(0, 0);

    // Validation for name
    if (isFormData.firstname.length < 3 || /\d/.test(isFormData.firstname)) {
      alert(
        "First name must be at least 3 characters and should not contain numbers"
      );
      return;
    }

    // Validation for phone
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(isFormData.phone)) {
      alert("Please enter a valid 10-digit phone number");
      return;
    }

    // Validation for email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(isFormData.email)) {
      alert("Please enter a valid email address");
      return;
    }

    // Validation for product range
    if (
      !isFormData.range.control &&
      !isFormData.range.motor &&
      !isFormData.range.sensor
    ) {
      alert("Please select at least one product range");
      return;
    }

    // If all validations pass, send the form
    emailjs
      .sendForm(
        "service_x26mf1q",
        "template_9dmusq9",
        form.current,
        "lkGUtm3wLpPI6XDYw"
      )
      .then(
        (result: any) => {
          setFormData({
            firstname: "",
            lastname: "",
            email: "",
            country: "",
            zipcode: "",
            phone: "",
            message: "",
            city: "",
            range: {
              control: false,
              motor: false,
              sensor: false,
            },
          });
          setIsSuccess(true);
          console.log(result.text);
        },
        (error: any) => {
          console.log(error.text);
        }
      );
  };

  const handleChange = (e: any) => {
    setFormData({
      ...isFormData,
      [e.target.name]: e.target.value,
    });
  };

  const checkHandleChange = (e: any) => {
    const { name, type, value, checked }: any = e.target;
    setFormData((prevData) => {
      if (type === "checkbox") {
        return {
          ...prevData,
          range: {
            ...prevData.range,
            [name]: checked,
          },
        };
      } else {
        return {
          ...prevData,
          [name]: value,
        };
      }
    });
  };
  console.log(isFormData);
  return (
    <div className="bg-[#ffff] text-[#2b403b]">
      <div className="container mx-auto px-[20px] lg:px-[5rem] py-[15px]">
        {isSuccess && (
          <div
            className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
            role="alert"
          >
            <span className="font-medium">Success!</span> Thanks you for
            contacting us, we contact you shortly.
          </div>
        )}
        <form ref={form} method="" onSubmit={handleSubmit}>
          <div className="mb-5 lg:w-[50%]">
            <label
              htmlFor="firstname"
              className="block mb-1 text-[16px] text-[#2b403b] "
            >
              Firstname*
            </label>
            <input
              type="text"
              id="firstname"
              name="firstname"
              className=" md:px-[0.625rem] md:py-[1.125rem] p-1 focus-visible:border-orange-600 bg-gray-50 border border-gray-300 text-[#2b403b] text-[16px] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
              value={isFormData?.firstname}
              onChange={(e: any) => {
                handleChange(e);
              }}
            />
          </div>
          <div className="mb-5 lg:w-[50%]">
            <label
              htmlFor="lastname"
              className="block mb-1 text-[16px] text-[#2b403b] "
            >
              Lastname*
            </label>
            <input
              type="text"
              id="lastname"
              name="lastname"
              className=" md:px-[0.625rem] md:py-[1.125rem] p-1 focus-visible:border-orange-600 bg-gray-50 border border-gray-300 text-[#2b403b] text-[16px] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full "
              value={isFormData?.lastname}
              onChange={(e: any) => {
                handleChange(e);
              }}
            />
          </div>
          <div className="mb-5 lg:w-[50%]">
            <label
              htmlFor="email"
              className="block mb-1 text-[16px] text-[#2b403b] "
            >
              Email*
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className=" md:px-[0.625rem] md:py-[1.125rem] p-1 focus:ring-blue-500 focus:border-blue-500 bg-gray-50 border  text-[#2b403b] text-[16px] rounded-lg block w-full  "
              value={isFormData?.email}
              onChange={(e: any) => {
                handleChange(e);
              }}
            />
          </div>
          <div className="mb-5 lg:w-[50%]">
            <label
              htmlFor="country"
              className="block mb-1 text-[16px] text-[#2b403b] "
            >
              Select an option
            </label>
            <select
              id="country"
              name="country"
              className="  md:px-[0.625rem] md:py-[1.125rem] p-1 focus:ring-blue-500 focus:border-blue-500 bg-gray-50 border  text-[#2b403b] text-[16px] rounded-lg block w-full"
              value={isFormData?.country}
              onChange={(e: any) => {
                handleChange(e);
              }}
            >
              <option selected>Select</option>
              <option value="bangladesh">Bangladesh</option>
              <option value="india">India</option>
              <option value="nepal">Nepal</option>
              <option value="pakistan">Pakistan</option>
            </select>
          </div>
          <div className="mb-5 lg:w-[50%]">
            <label
              htmlFor="zipcode"
              className="block mb-1 text-[16px] text-[#2b403b] "
            >
              Project Zip Code*
            </label>
            <input
              type="number"
              id="zipcode"
              name="zipcode"
              className="  md:px-[0.625rem] md:py-[1.125rem] p-1 focus:ring-blue-500 focus:border-blue-500 bg-gray-50 border  text-[#2b403b] text-[16px] rounded-lg block w-full "
              value={isFormData?.zipcode}
              onChange={(e: any) => {
                handleChange(e);
              }}
            />
          </div>
          <div className="mb-5 lg:w-[50%]">
            <label
              htmlFor="city"
              className="block mb-1 text-[16px] text-[#2b403b] "
            >
              Project City*
            </label>
            <input
              type="text"
              id="city"
              name="city"
              className="  md:px-[0.625rem] md:py-[1.125rem] p-1 focus:ring-blue-500 focus:border-blue-500 bg-gray-50 border  text-[#2b403b] text-[16px] rounded-lg block w-full "
              value={isFormData?.city}
              onChange={(e: any) => {
                handleChange(e);
              }}
            />
          </div>
          <div className="mb-5 lg:w-[50%]">
            <label
              htmlFor="phone"
              className="block mb-1 text-[16px] text-[#2b403b] "
            >
              Fixphone*
            </label>
            <input
              type="number"
              id="phone"
              name="phone"
              className="  md:px-[0.625rem] md:py-[1.125rem] p-1 focus:ring-blue-500 focus:border-blue-500 bg-gray-50 border  text-[#2b403b] text-[16px] rounded-lg block w-full "
              value={isFormData?.phone}
              onChange={(e: any) => {
                handleChange(e);
              }}
            />
          </div>
          <div className="mb-5 lg:w-[50%]">
            <label
              htmlFor="message"
              className="block mb-2 text-sm font-medium text-[#2b403b]"
            >
              Project Description*
            </label>
            <textarea
              id="message"
              name="message"
              rows={4}
              className="block p-2.5 w-full text-sm text-[#2b403b] bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
              placeholder="Your Message here"
              value={isFormData?.message}
              onChange={(e: any) => {
                handleChange(e);
              }}
            ></textarea>
          </div>
          <div className="mb-5 lg:w-[50%]">
            <label
              htmlFor="range"
              className="block mb-1 text-[16px] text-[#2b403b] "
            >
              Product range **
            </label>
            <div className="flex items-center mb-2">
              <input
                name="control"
                id="default-checkbox"
                type="checkbox"
                className="w-4 h-4 text-orange-600 bg-gray-100 border-gray-300 rounded focus:text-orange-600 "
                checked={isFormData?.range?.control}
                onChange={(e: any) => {
                  checkHandleChange(e);
                }}
              />
              <label
                htmlFor="default-checkbox"
                className="ms-2 text-sm font-medium text-[#2b403b]"
              >
                Controls and remote controls
              </label>
            </div>
            <div className="flex items-center mb-2">
              <input
                id="Awning"
                name="motor"
                type="checkbox"
                className="w-4 h-4 text-orange-600 bg-gray-100 border-gray-300 rounded focus:text-orange-600 "
                checked={isFormData?.range?.motor}
                onChange={(e: any) => {
                  checkHandleChange(e);
                }}
              />
              <label
                htmlFor="Awning"
                className="ms-2 text-sm font-medium text-[#2b403b]"
              >
                Motors
              </label>
            </div>
            <div className="flex items-center mb-2">
              <input
                id="blind"
                name="sensor"
                type="checkbox"
                className="w-4 h-4 text-orange-600 bg-gray-100 border-gray-300 rounded focus:text-orange-600 "
                checked={isFormData?.range?.sensor}
                onChange={(e: any) => {
                  checkHandleChange(e);
                }}
              />
              <label
                htmlFor="blind"
                className="ms-2 text-sm font-medium text-[#2b403b]"
              >
                Sensors
              </label>
            </div>
          </div>
          <div className="flex justify-center">
            <input
              type="submit"
              className="rounded-full border-[1px] cursor-pointer bg-[#2b403b]  px-6 py-3"
              value="Send"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contactus;
