import React, { useEffect, useState } from "react";
import "./footer.css";
import { Link, useLocation } from "react-router-dom";

export default function Header() {
  const location = useLocation();
  const [isSticky, setIsSticky] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window?.location?.href]);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;

      // You can adjust this value based on your design
      if (offset > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach the event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="h-auto">
      <div className="hidden md:block z-[600] py-[0.5rem] bg-[#dad3b7]  text-[#2b403b]">
        <div className="flex px-[10vw] mr-auto ml-auto max-w-full justify-between items-center">
          <nav className="bg-[#FFFFFF] hidden md:block rounded-[3.125rem] align-top px-[0.125rem]">
            <span className=""></span>
            <a
              download={true}
              href="/pdf/Decode.pdf"
              target="_blank"
              className="text-xs leading-7  flex  z-10 text-[#2b403b] font-medium border-0 bg-none py-0 px-[1rem] items-center justify-center rounded-[3.125rem] no-underline"
            >
              Download Broucher
            </a>
          </nav>
          <div className="font-medium uppercase leading-4 text-sm m-auto px-[1.5rem]">
            A question? A Project? Call us on +91 88669 77669
          </div>
        </div>
      </div>
      <div
        className={`header-container  h-auto ${
          isSticky ? "header-sticky" : ""
        }`}
      >
        <div className=" w-full text-center h-16 md:h-20  z-[500] translate-y-0 border-b border-[#e3dbd1] bg-white text-[#25485a]">
          <div className="flex  fixed top-0 translate-y-0 items-center h-full justify-between w-full  px-[10vw]">
            <div className="flex items-center ">
              <button
                type="button"
                onClick={toggleSidebar}
                className="header-nav-toggle block lg:hidden p-3 border-none me-[1.25rem] ms-[-0.75rem]"
              >
                <i className="fa-solid fa-list"></i>
              </button>
              {isSidebarOpen && (
                <div
                  className={`overlay ${isSidebarOpen ? "overlay-open" : ""}`}
                  onClick={toggleSidebar}
                ></div>
              )}
              <div
                className={`sidebar h-[100vh] ${isSidebarOpen ? "open" : ""}`}
              >
                <div className="flex items-center p-3 border-b-2 justify-between">
                  <div className="header-logo">
                    <img
                      src={require("../images/logo.png")}
                      alt=""
                      className="md:h-10 h-8 m-auto"
                    />
                  </div>
                  <button className="close-btn " onClick={toggleSidebar}>
                    &times;
                  </button>
                </div>
                <ul className="flex flex-col h-full p-2 mb-0 items-start  list-none mt-0">
                  <li className="">
                    <button className="relative block text-sm   md:text-[1.125rem] text-teal-900  leading-10 whitespace-no-wrap pt-[0.25rem] px-[1.5rem] text-start hover:text-[#25485a] ">
                      <Link
                        to="/"
                        onClick={toggleSidebar}
                        className={`${
                          location?.pathname === "/"
                            ? "border-b-[#9b5823] text-[#9b5823]"
                            : "border-b-transparent"
                        } hover:border-b-[#9b5823] hover:text-[#9b5823] border-b-2 `}
                      >
                        Home
                      </Link>
                    </button>
                  </li>
                  <li className="">
                    <button className="relative block text-sm   md:text-[1.125rem] text-teal-900  leading-10 whitespace-no-wrap pt-[0.25rem] px-[1.5rem] text-start  hover:text-[#25485a] ">
                      <Link
                        to="/product"
                        onClick={toggleSidebar}
                        className={`${
                          location?.pathname === "/product"
                            ? "border-b-[#9b5823] text-[#9b5823]"
                            : "border-b-transparent"
                        } hover:border-b-[#9b5823] hover:text-[#9b5823] border-b-2 `}
                      >
                        Product
                      </Link>
                    </button>
                  </li>
                  <li className="">
                    <button className="relative block  text-sm  md:text-[1.125rem] text-teal-900  leading-10 whitespace-no-wrap pt-[0.25rem] px-[1.5rem] text-start  hover:text-[#25485a]]">
                      <Link
                        to="/about"
                        onClick={toggleSidebar}
                        className={`${
                          location?.pathname === "/about"
                            ? "border-b-[#9b5823] text-[#9b5823]"
                            : "border-b-transparent"
                        } hover:border-b-[#9b5823] hover:text-[#9b5823] border-b-2 `}
                      >
                        About us
                      </Link>
                    </button>
                  </li>
                  <li className="">
                    <button className="relative block text-sm  md:text-[1.125rem] text-teal-900  leading-10 whitespace-no-wrap pt-[0.25rem] px-[1.5rem] text-start  hover:text-[#25485a]">
                      <Link
                        to="/contactUs"
                        onClick={toggleSidebar}
                        className={`${
                          location?.pathname === "/contactUs"
                            ? "border-b-[#9b5823] text-[#9b5823]"
                            : "border-b-transparent"
                        } hover:border-b-[#9b5823] hover:text-[#9b5823] border-b-2 `}
                      >
                        Contact us
                      </Link>
                    </button>
                  </li>
                </ul>
              </div>
              <Link to={"/"}>
                <div className="header-logo m-auto">
                  <img
                    src={require("../images/logo.png")}
                    alt=""
                    className="md:h-10 h-8 m-auto"
                  />
                </div>
              </Link>
            </div>
            <div className="hidden lg:block">
              <ul className="flex  h-full p-0 mb-0 items-center justify-start list-none mt-0">
                <li className="">
                  <button className="relative block pe-[1.625rem]   text-[1.125rem] text-teal-900 text-lg leading-10 whitespace-no-wrap pt-[0.25rem] px-1.5rem] text-start  hover:text-[#25485a] ">
                    <Link
                      to="/"
                      className={`${
                        location?.pathname === "/"
                          ? "border-b-[#9b5823] text-[#9b5823]"
                          : "border-b-transparent"
                      } hover:border-b-[#9b5823] hover:text-[#9b5823] border-b-2 `}
                    >
                      Home
                    </Link>
                  </button>
                </li>
                <div className="h-5 w-[2px] mr-2 bg-[#E3DBD1]"></div>

                <li className="">
                  <button className="relative pe-[1.625rem] ps-[1.625rem] block  text-[1.125rem] text-teal-900 text-lg leading-10 whitespace-no-wrap pt-[0.25rem] px-1.5rem] text-start  hover:text-[#25485a] ">
                    <Link
                      to="/product"
                      className={`${
                        location?.pathname === "/product"
                          ? "border-b-[#9b5823] text-[#9b5823]"
                          : "border-b-transparent"
                      } hover:border-b-[#9b5823] hover:text-[#9b5823] border-b-2 `}
                    >
                      Product
                    </Link>
                  </button>
                </li>
                <div className="h-5 w-[2px] mr-2 bg-[#E3DBD1]"></div>
                <li className="">
                  <button className="relative block pe-[1.625rem] ps-[1.625rem] text-[1.125rem] text-teal-900 text-lg leading-10 whitespace-no-wrap pt-[0.25rem] px-1.5rem] text-start hover:text-[#25485a] ">
                    <Link
                      to="/about"
                      className={`${
                        location?.pathname === "/about"
                          ? "border-b-[#9b5823] text-[#9b5823]"
                          : "border-b-transparent"
                      } hover:border-b-[#9b5823] hover:text-[#9b5823] border-b-2 `}
                    >
                      About us
                    </Link>
                  </button>
                </li>
                <div className="h-5 w-[2px] mr-2 bg-[#E3DBD1]"></div>
                <li className="">
                  <button className="relative pe-[1.625rem] ps-[1.625rem] block text-[1.125rem] text-teal-900 text-lg leading-10 whitespace-no-wrap pt-[0.25rem] px-1.5rem] text-start  hover:text-[#25485a] ">
                    <Link
                      to="/contactUs"
                      className={`${
                        location?.pathname === "/contactUs"
                          ? "border-b-[#9b5823] text-[#9b5823]"
                          : "border-b-transparent"
                      } hover:border-b-[#9b5823] hover:text-[#9b5823] border-b-2 `}
                    >
                      Contact us
                    </Link>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
