import React, { useEffect, useState } from "react";
import "./homepage.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";

const owlCarouselOptions = {
  items: 3,
  nav: true,
  margin: 50,
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    1024: {
      items: 3, 
    },
  },
};
const HomePage = () => {
  const [items, setItems]: any = useState(false);
  // console.log(items);
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setItems(width >= 700);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); 
  return (
    <main className="flex-grow block">
      <section className="relative md:h-[90vh] h-[40vh] flex pt-[8.0625rem] pb-[6.5rem] rounded-e-none text-[#2b403b] ">
        <div className="max-w-full w-full h-full mr-auto ml-auto px-[10vw]">
          <div className="absolute w-full h-full top-0 overflow-hidden inset-0">
            <img
              src={require("../../images/productbanner.jpg")}
              alt=""
              className="w-full h-[100vh]"
              style={{ objectFit: "cover" }}
            />
          </div>
          <div className="flex flex-wrap -mr-3 -ml-3">
            <div className="relative md:w-[25rem] w-full  pr-3 pl-3">
              <div className="relative md:p-6 px-4 py-2 rounded-lg z-10 text-teal-900 bg-[#fff] shadow-lg shadow-black box-border">
                <h1 className="banner-with-frame-title mb-2 md:mb-4 font-bold text-lg md:text-4xl">
                  Enjoy silent motorization for your interior blinds
                </h1>
                <p className="md:text-lg text-xs mb-2">
                  Take advantage of the quiet opening and closing of your blinds
                  with our WireFree and silent motors.
                </p>
                <Link to="/product">
                  <span className=" btn relative text-[#ffff] bg-[#2b403b] inline-flex items-center justify-center align-middle py-[0.20rem] md:py-[0.75rem] px-[0.75rem]  md:px-[1.75rem] border-0 text-center  font-medium md:text-base text-xs leading-6 rounded-[2rem] no-underline whitespace-no-wrap max-w-full">
                    <span>Discover more</span>
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="rounded-es-[12.5rem] mb-5 pt-28 md:pt-11 bg-[#2b403b] text-[#ffff] relative pb-[6.5rem]">
        <div className="max-w-full w-full h-full mr-auto ml-auto px-[10vw]">
          <div className="md:w-[50%] w-full  md:mt-0">
            <h2
              className="md:text-[3.6125rem] md:text-5xl text-3xl font-bold leading-[105%]  "
              style={{ letterSpacing: "0.03125rem" }}
            >
              Decode, a partner for your connected home
            </h2>
          </div>
          <ul
            className="p-0 grid lg:grid-cols-3 md:grid-cols-2  grid-cols-1  list-none mt-[5rem] "
            style={{
              gridGap: "1.5rem",
            }}
          >
            <li>
              <div className="relative h-[280px] md:h-full w-full overflow-hidden  section1">
                <div className="absolute h-full w-full z-10">
                  <img
                    src={require("../../images/group.jfif")}

                    alt=""
                    className="absolute top-0 w-full h-full object-cover  rounded-[20px]"
                  />
                </div>
                <a href="#Shutters">
                  <h3 className="univers-title absolute z-20 text-[#2b403b] text-xl font-medium bg-[#dad3b7] hover:bg-[#9b5823] hover:text-[#ffff]">
                    Shutters and screens
                  </h3>
                </a>
              </div>
            </li>
            <li>
              <div className="relative  w-full  h-[280px] md:h-full overflow-hidden section1">
                <div className="absolute h-full w-full z-10">
                  <img
                    src={require("../../images/curtain.jpg")}
                    alt=""
                    className="absolute top-0 w-full h-full rounded-[20px] object-cover"
                  />
                </div>
                <a href="#Blinds">
                  <h3 className="univers-title absolute z-20 text-[#2b403b] text-xl font-medium bg-[#dad3b7] hover:bg-[#9b5823] hover:text-[#ffff]">
                    Blinds and Curtains
                  </h3>
                </a>
              </div>
            </li>
            <li>
              <div className="relative h-[280px] md:h-full w-full overflow-hidden  section1">
                <div className="absolute h-full w-full z-10">
                  <img
                    src={require("../../images/shutters.jpg")}
                    alt=""
                    className="absolute top-0 w-full h-full  rounded-[20px] object-cover"
                  />
                </div>
                <a href="#Terrace">
                  <h3 className="univers-title absolute z-20 text-[#2b403b] text-xl font-medium bg-[#dad3b7] hover:bg-[#9b5823] hover:text-[#ffff]">
                    Terrace and outdoor
                  </h3>
                </a>
              </div>
            </li>
            {/* <li>
              <div className="relative  h-[280px] md:h-full w-full overflow-hidden section1">
                <div className="absolute h-full w-full z-10">
                  <img
                    src={require("../../images/group.jfif")}
                    alt=""
                    className="absolute top-0 w-full h-full object-cover  rounded-[20px]"
                  />
                </div>
                <a href="#Access">
                  <h3 className="univers-title absolute z-20 text-[#2b403b] text-xl font-medium bg-[#dad3b7] hover:bg-[#9b5823] hover:text-[#ffff]">
                    Access
                  </h3>
                </a>
              </div>
            </li> */}
          </ul>
        </div>
      </section>
      {/* <section className="video-list mb-5 relative bg-[#fff] text-[#2b403b] py-6 md:py-[6.5rem] box-border">
        <div className="container">
          <div className="relative w-full pr-3 pl-3 col ">
            <p className="video-list-headTitle font-medium text-xl mb-2">
              Our videos
            </p>
            <h2 className="video-list-title mb-8 leading-[105%] font-medium text-2xl md:text-5xl">
              Decode Web TV
            </h2>
            <div className="video-list-content mb-0"></div>
          </div>
        </div>
        <div className="relative ">
          <div className="slider-back hidden md:block"></div>
          <OwlCarousel
            {...owlCarouselOptions}
            items={3}
            className="owl-theme h-full"
            nav
            dots={false}
            margin={10}
          >
            {!items ? <div></div> : null}
            <div className="w-full p-5 md:p-0">
              <div
                className="relative  rounded-lg h-0"
                style={{
                  padding: "calc(30.25% - 4px)",
                }}
              >
                <img
                  src={require("../../images/slider.jpg")}
                  className="absolute rounded-[20px] top-0 left-0 w-full h-full object-cover"
                  alt=""
                  style={{ boxShadow: "0 0 0 2px white" }}
                />
                <button
                  type="button"
                  style={{ background: "white" }}
                  className="player-video-btn absolute bg-white text-[#2b403b] shadow-sm inline-flex items-center justify-center align-middle text-center  font-medium text-base leading-6 rounded-[30px] no-underline whitespace-no-wrap max-w-full shadow-white left-[39%] top-[42%] translate-x-[-50X] translate-y-[-50PX] "
                >
                  Play
                </button>
              </div>
              <div className="player-video-title md:text-white text-[#2b403b] font-medium mt-2">
                Maximize Privacy – Decode Powered Motorized Window Coverings
              </div>
            </div>
            <div className="w-full p-5 md:p-0">
              <div
                className="relative  rounded-lg h-0"
                style={{
                  padding: "calc(30.25% - 4px)",
                }}
              >
                <img
                  src={require("../../images/slider.jpg")}
                  className="absolute rounded-[20px] top-0 left-0 w-full h-full object-cover"
                  alt=""
                  style={{ boxShadow: "0 0 0 2px white" }}
                />
                <button
                  type="button"
                  style={{ background: "white" }}
                  className="player-video-btn absolute bg-[#fff] text-[#2b403b] shadow-sm inline-flex items-center justify-center align-middle text-center  font-medium text-base leading-6 rounded-[30px] no-underline whitespace-no-wrap max-w-full shadow-white left-[39%] top-[42%] translate-x-[-50X] translate-y-[-50PX] "
                >
                  Play
                </button>
              </div>
              <div className="player-video-title md:text-white text-[#2b403b] font-medium mt-2">
                Maximize Privacy – Decode Powered Motorized Window Coverings
              </div>
            </div>
            <div className="w-full p-5 md:p-0">
              <div
                className="relative  rounded-lg h-0"
                style={{
                  padding: "calc(30.25% - 4px)",
                }}
              >
                <img
                  src={require("../../images/slider.jpg")}
                  className="absolute rounded-[20px] top-0 left-0 w-full h-full object-cover"
                  alt=""
                  style={{ boxShadow: "0 0 0 2px white" }}
                />
                <button
                  type="button"
                  style={{ background: "white" }}
                  className="player-video-btn absolute bg-[#fff] text-[#2b403b] shadow-sm inline-flex items-center justify-center align-middle text-center  font-medium text-base leading-6 rounded-[30px] no-underline whitespace-no-wrap max-w-full shadow-white left-[39%] top-[42%] translate-x-[-50X] translate-y-[-50PX] "
                >
                  Play
                </button>
              </div>
              <div className="player-video-title md:text-white text-[#2b403b] font-medium mt-2">
                Maximize Privacy – Decode Powered Motorized Window Coverings
              </div>
            </div>
            <div className="w-full p-5 md:p-0">
              <div
                className="relative  rounded-lg h-0"
                style={{
                  padding: "calc(30.25% - 4px)",
                }}
              >
                <img
                  src={require("../../images/slider.jpg")}
                  className="absolute rounded-[20px] top-0 left-0 w-full h-full object-cover"
                  alt=""
                  style={{ boxShadow: "0 0 0 2px white" }}
                />
                <button
                  type="button"
                  style={{ background: "white" }}
                  className="player-video-btn absolute bg-[#fff] text-[#2b403b] shadow-sm inline-flex items-center justify-center align-middle text-center  font-medium text-base leading-6 rounded-[30px] no-underline whitespace-no-wrap max-w-full shadow-white left-[39%] top-[42%] translate-x-[-50X] translate-y-[-50PX] "
                >
                  Play
                </button>
              </div>
              <div className="player-video-title md:text-white text-[#2b403b] font-medium mt-2">
                Maximize Privacy – Decode Powered Motorized Window Coverings
              </div>
            </div>
          </OwlCarousel>
        </div>
      </section> */}
      <section
        className="bg-white mb-14 text-[#2b403b] relative px-[1.2rem] md:px-[6.5rem]"
        id="B-346BD36F-AF2F-56A3-CC8D70F248DB230A"
      >
        <div className="container">
          <div className="mb-14 flex flex-wrap -mr-3 -ml-3">
            <div className=" relative w-full pr-3 pl-3">
              <div className="title_content">
                <h2 className="row-head-title font-medium md:text-5xl text-4xl leading-[100%]">
                  Go further
                </h2>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 -mr-3 -ml-3">
            <div className=" pr-3 mb-5 pl-3 ">
              <div
                className="cta-embed flex flex-col w-full h-full md:p-6 p-4 rounded-lg"
                style={{
                  boxShadow: "0 0 0 2px #2b403b",
                }}
              >
                <h4 className="cta-embed-title mb-3 font-medium text-lg md:text-2xl leading-[110%]">
                  Find a point of sale
                </h4>
                <p className="cta-embed-text md:mb-7 mb-5 md:text-base text-sm leading-[120%]">
                  Find an installer near you
                </p>
                <Link
                  to="/contactUs"
                  className="text-[#ffff] py-[0.75px] px-[0.75px]  md:px-[1.75rem] md:pt-[0.9375rem] md:pb-[1.0625rem] mt-auto relative inline-flex items-center justify-center align-middle text-center border-solid border-4 border-transparent  font-medium md:text-base text-sm leading-6 rounded-[30px] no-underline whitespace-no-wrap  bg-[#2b403b]  find-btn"
                >
                  <span
                    className="overflow-hidden "
                    style={{ textOverflow: "ellipsis" }}
                  >
                    Find a point of sale&nbsp;
                  </span>
                </Link>
              </div>
            </div>
            <div className=" pr-3 mb-5 pl-3 ">
              <div
                className="cta-embed flex flex-col w-full h-full md:p-6 p-4 rounded-lg"
                style={{ boxShadow: "0 0 0 2px #2b403b" }}
              >
                <h4 className="cta-embed-title mb-3 font-medium text-lg md:text-2xl leading-[110%]">
                  Do you need some advice?
                </h4>
                <p className="cta-embed-text md:mb-7 mb-5 md:text-base text-sm leading-[120%]">
                  Are you unsure? Contact us. We’ll help you find the best
                  solution for your project!
                </p>

                <Link
                  to="/contactUs"
                  className="text-[#ffff] py-[0.75px] px-[0.75px]  md:px-[1.75rem] md:pt-[0.9375rem] md:pb-[1.0625rem] mt-auto relative inline-flex items-center justify-center align-middle text-center border-solid border-4 border-transparent  font-medium md:text-base text-sm leading-6 rounded-[30px] no-underline whitespace-no-wrap  bg-[#2b403b]  find-btn"
                >
                  <span
                    className="overflow-hidden "
                    style={{ textOverflow: "ellipsis" }}
                  >
                    Launch the Selection Assistant&nbsp;
                  </span>
                </Link>
              </div>
            </div>
            <div className=" pr-3 mb-5 pl-3 ">
              <div
                className="cta-embed flex flex-col w-full h-full md:p-6 p-4 rounded-lg"
                style={{ boxShadow: "0 0 0 2px #2b403b" }}
              >
                <h4 className="cta-embed-title mb-3 font-medium text-lg md:text-2xl leading-[110%]">
                  Get a quote
                </h4>
                <p className="cta-embed-text md:mb-7 mb-5 md:text-base text-sm leading-[120%]">
                  Contact certified professionals near you to carry out your
                  project or make repairs!
                </p>

                <Link
                  to="/contactUs"
                  className="text-[#ffff] py-[0.75px] px-[0.75px]  md:px-[1.75rem] md:pt-[0.9375rem] md:pb-[1.0625rem] mt-auto relative inline-flex items-center justify-center align-middle text-center border-solid border-4 border-transparent  font-medium md:text-base text-sm leading-6 rounded-[30px] no-underline whitespace-no-wrap  bg-[#2b403b]  find-btn"
                >
                  <span
                    className="overflow-hidden "
                    style={{ textOverflow: "ellipsis" }}
                  >
                    I would like to get a quote&nbsp;
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
export default HomePage;
