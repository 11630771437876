import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./about.css";
import { Link } from "react-router-dom";
const About = () => {
  return (
    <div>
      <div className="maincontent">
        <div className="text-content-image  bg-white before:bg-white py-[1.5rem] lg:py-[4.5rem]">
          <div className="container px-[5vw] fade-up fade-up-active m-auto position-relative zindex-5 pt-5">
            <div className="grid grid-cols-1  md:grid-cols-2 gap-5">
              <div className="text-content-image-wrapper  text-[#2B403B] flex items-center">
                <div>
                  <h2 className="text-content-image-title text-[1.7rem] lg:text-[2.7rem] font-[500] lg:mb-6">
                    About Decode
                  </h2>
                  <p className=" text-[1rem] lg:text-[1.30rem] font-[500] lg:leading-7 mb-2 ">
                    Decode takes care of your personal data at each stage of the
                    recruitment process and after its completion. You will find
                    all information in our recruitment privacy policy.
                  </p>
                  <div className="text-content-image-title text-[1.7rem] lg:text-[2.7rem] font-[500] lg:mb-6">
                    <p className=" text-[1rem] lg:text-[1.30rem] font-[500] lg:leading-7 mb-2 ">
                      All applications must be submitted in Smartrecruiters (all
                      paper and email applications will be automatically
                      rejected).
                    </p>
                  </div>
                  <button className="rounded-full border-[1px] bg-[#2B403B] text-center px-3 py-3 box-border lg:px-5 lg:py-5">
                    <span className="text-[#ffff] font-[600] text-[12px] lg:text-[16px]">
                      Apply Today
                    </span>
                  </button>
                </div>
              </div>
              <div className="">
                <div className="grid grid-cols-2 gap-5 ">
                  <div className="image-one  md:h-[200px]  lg:h-[320px] mx-auto xl:w-full">
                    <img
                      src={require("../../images/shutters.jpg")}
                      alt=""
                      className="rounded-lg h-full  lg:w-[300px] xl:w-[100%] object-cover"
                    />
                  </div>
                  <div className="image-three md:h-[200px] lg:h-[320px] mx-auto xl:w-full">
                    <img
                      src={require("../../images/backgrounImage1.jpg")}
                      alt=""
                      className="rounded-lg h-full  lg:w-[300px] xl:w-[100%] object-cover"
                    />
                  </div>
                  <div className="image-two md:h-[200px] lg:h-[320px] mx-auto xl:w-full">
                    <img
                      src={require("../../images/productbanner.jpg")}
                      alt=""
                      className="rounded-lg h-full  lg:w-[300px] xl:w-[100%] object-cover"
                    />
                  </div>
                  <div className="image-four md:h-[200px] lg:h-[320px] mx-auto xl:w-full">
                    <img
                      src={require("../../images/curtain.jpg")}
                      alt=""
                      className="rounded-lg h-full  lg:w-[300px] xl:w-[100%] object-cover"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" bg-white mb-10 lg:mb-28 md:mt-5">
          <div className="container m-auto px-[16px] lg:px-[5vw]">
            <div className="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 lg:gap-5">
              <div className="image-section lg:m-0 shadow-xl rounded-lg lg:col-span-1 bg-gradient-to-r from-[#dad3b764] to-[#2b403b65] flex justify-center items-center">
                <div className="border-0 overflow-hidden md:px-4 text-[1.5rem] md:text-[2.5rem] md:leading-10 text-center md:text-start lg:p-4 flex flex-col items-center justify-center font-[700] rounded-lg">
                  <span className="px-7 text-[#2B403B]">
                    What Our Clients Say About Decode
                  </span>
                </div>
              </div>
              <div className="policy-content-section h-full px-4 py-8 shadow-xl   rounded-lg lg:col-span-2">
                <div className="">
                  <OwlCarousel
                    items={1}
                    className="owl-theme h-full"
                    nav={true}
                    dots={false}
                    margin={10}
                    draggable
                    loop
                  >
                    <div className="item px-5 md:p-0 mr-5">
                      <div className=" ">
                        <i className="fa-solid fa-quote-left p-4 bg-[#2b403b] text-white rounded-lg shadow-lg"></i>
                      </div>

                      <div className="testimonial flex items-center gap-5 mt-5">
                        <div className="testimonial-image ">
                          <img
                            src={require("../../images/testimonialthree.webp")}
                            alt=""
                            className="w-full h-full rounded-full"
                          />
                        </div>
                        <div className="testimonial-content">
                          <p className="text-[#2b403b] text-md md:text-lg mt-2">
                            I recently got my hands on Decode, They provide very
                            good Services
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="item px-5 md:p-0 mr-5">
                      <span className=" ">
                        <i className="fa-solid fa-quote-left  p-4 bg-[#2b403b] text-white rounded-lg shadow-lg"></i>
                      </span>
                      <div className="testimonial flex items-center gap-5 mt-5">
                        <div className="testimonial-image ">
                          <img
                            src={require("../../images/testimonialone.webp")}
                            alt=""
                            className="w-full h-full rounded-full"
                          />
                        </div>
                        <div className="testimonial-content">
                          <p className="text-[#2b403b] text-md md:text-lg mt-2">
                          Their product provide smart way to automate your motorized systems.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="item px-5 md:p-0 mr-5">
                      <div className=" ">
                        <i className="fa-solid fa-quote-left  p-4 bg-[#2b403b] text-white rounded-lg shadow-lg"></i>
                      </div>
                      <div className="testimonial flex items-center gap-5 mt-5">
                        <div className="testimonial-image ">
                          <img
                            src={require("../../images/testimonialtwo.webp")}
                            alt=""
                            className="w-full h-full rounded-full"
                          />
                        </div>
                        <div className="testimonial-content">
                          <p className="text-[#2b403b] text-md md:text-lg mt-2">
                            Thier product Simple, efficient, and hassle-free.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* Repeat the above div.item for each slide */}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-10 lg:mb-28 md:mt-5">
          <div className="container  m-auto px-[16px] lg:px-[5vw]">
            <div className="image-section lg:m-0 ">
              <div className=" flex flex-col items-center justify-center rounded-lg w-full">
                <h2 className="text-[#2B403B] font-medium mb-1 md:mb-3 text-[1.8rem] md:text-[2.5rem]">
                  We Have Social Networks
                </h2>
                <span className=" text-[#2B403B] text-[16px] md:text-[18px] mb-2 md:mb-5">
                  Follow us and keep up to date with the freshest news!
                </span>
                <div className="grid grid-cols-3 md:grid-cols-3 md:divide-x">
                  <div className="text-center px-5 md:w-[180px]">
                    <div>
                      <i className="fa-brands fa-instagram text-[1.5rem] md:text-[2.5rem] text-[#2B403B]"></i>
                    </div>
                    <div className="pt-4">
                      <span className="mb-1">Instagram</span>
                      <p className="text-sm text-[#2B403B] mb-0">@decode</p>
                    </div>
                  </div>
                  <div className="text-center px-5 md:w-[180px]">
                    <div>
                      <i className="fa-brands fa-facebook text-[1.5rem] md:text-[2.5rem] text-[#2B403B]"></i>
                    </div>
                    <div className="pt-4">
                      <span className="mb-1">Facebook</span>
                      <p className="text-sm text-[#2B403B] mb-0">@decode</p>
                    </div>
                  </div>
                  <div className="text-center px-5 md:w-[180px]">
                    <div>
                      <i className="fa-brands fa-linkedin text-[1.5rem] md:text-[2.5rem] text-[#2B403B]"></i>
                    </div>
                    <div className="pt-4">
                      <span className="mb-1">LinkedIn</span>
                      <p className="text-sm text-[#2B403B] mb-0">@decode</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-10 lg:mb-28 md:mt-5">
          <div className="container  m-auto px-[16px] lg:px-[5vw]">
            <div className="image-section py-9 md:py-18 lg:py-28 lg:m-0  bg-[#dad3b7] rounded-lg">
              <div className=" lg:p-4 flex flex-col items-center justify-center font-[700] tracking-wide rounded-lg w-full">
                <h2 className="text-[#2B403B] font-medium text-[16px] md:text-[1.8rem] ">
                  Want to work with us? Let’s talk
                </h2>
                <span className="px-7 text-[#2B403B] underline mb-5 text-[1.5rem] md:text-[2.5rem]">
                  info@decodemotor.com
                </span>
                <Link
                  to="/contactUs"
                  className="text-[#ffff] font-[600] text-[12px] lg:text-[16px]"
                >
                  <button className="rounded-full  bg-[#2B403B] text-center px-5 py-2 box-border lg:px-5 lg:py-3">
                    Contact Us
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
