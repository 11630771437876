import "./App.css";
import {  BrowserRouter as Router,  } from "react-router-dom";
import CustomRoute from "./routes/CustomRoute";

function App() {
  return (
     <Router>
       <CustomRoute/>
    </Router>
  );
}

export default App;
