import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { data } from "../../productDetail/data";

export default function Product() {
  const [click, setClick] = useState(true);
  const [selectedTypes, setSelectedTypes]: any = useState({
    remote: false,
    motors: false,
  });
  const [searchTerm, setSearchTerm]: any = useState("");
  const [filteredData, setFilteredData]: any = useState([]);

  const handleChange = (type: any) => {
    setSelectedTypes({
      ...selectedTypes,
      [type]: !selectedTypes[type],
    });
  };

  const handleSearch = (e: any) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const updatedData = data?.filter((product) => {
      const typeFilter =
        (!selectedTypes.remote && !selectedTypes.motors) ||
        (selectedTypes.remote && product.type === "remote") ||
        (selectedTypes.motors && product.type === "motors");

      const nameFilter = product?.name
        .toLowerCase()
        .includes(searchTerm?.toLowerCase());
      return typeFilter && nameFilter;
    });
    setFilteredData(updatedData);
  }, [data, selectedTypes, searchTerm]);
  return (
    <main>
      <div className="bg-[#2b403b]  text-white">
        <section className="plp-header relative bg-[#2b403b] flex min-h-0  lg:min-h-[21.6vw] p-0 lg:py-20">
          <div className="container mx-auto px-[10vw] w-full hidden lg:block">
            <div className="plp-header-content w-full md:w-[36.33%]">
              <h1
                id="title-page"
                className="plp-header-title mb-4 font-medium text-4xl leading-[105%]"
              >
                Our Roller blind solution
              </h1>
              <p className="md:text-lg text-sm leading-[125%]">
                Enhance the sophistication of your interior with our seamless,
                motorized systems designed to complement a variety of roller
                blinds styles.
              </p>
            </div>
            <div
              style={{ insetInlineEnd: "0", borderEndStartRadius: "18.75rem" }}
              className="plp-header-visual absolute h-full top-0 overflow-hidden  w-[50vw] rounded-ss-[18.75rem] "
            >
              <img
                src={require("../../images/productbanner.jpg")}
                alt="visual"
                className="h-full w-full object-cover"
              />
            </div>
          </div>
          <div className="block lg:hidden mb-5">
            <div className="container flex flex-col mx-auto px-2rounded-ss-2xl  rounded-ss-2xl rounded-ee-2xl md:px-[10vw] px-2 w-full items-center">
              <div className="plp-header-content shadow-md relative z-10 shadow-white order-1 w-full p-4 rounded-lg -mt-20 bg-[#fff] text-[#2b403b]">
                <h1
                  id="title-page"
                  className="plp-header-title md:mb-4 mb-2 font-medium md:text-4xl text-2xl leading-[105%]"
                >
                  Our curtains solutions
                </h1>
                <p className="md:text-lg text-sm leading-[125%]">
                  Add a touch of elegance to your interior with our quiet
                  motorised systems that adapt to all types of curtains: simple
                  pleat, pinch pleat, flat panel or rippled.
                </p>
              </div>
              <div className="plp-header-visual order-none relative w-screen  h-[50vw] md:h-[80vw] -mx-3 ">
                <img
                  src={require("../../images/visuals.jpg")}
                  alt="visual"
                  className="h-full w-full object-cover rounded-es-2xl rounded-ee-2xl "
                />
              </div>
            </div>
          </div>
        </section>
        <section className="plp-main bg-[#ffff] text-[#2b403b]">
          <div className="container max-w-full px-[5vw] mx-auto w-full">
            <div className="grid md:grid-cols-2 grid-cols-1 text-center md:text-left py-6 mb-5 border-b-2 border-b-[#dad3b7]">
              <p className="font-medium md:text-2xl text-xl mb-2">
                <span>2</span> product(s) founds
              </p>

              {/* <div className="form-line m-auto flex justify-center md:justify-end items-center md:gap-5 w-full mb-0 z-30 relative p-0">
                <label className="block mb-2 text-sm font-medium me-2 md:me-0 text-gray-900 ">
                  Sort By
                </label>
                <select className="bg-white  border-2 border-[#dad3b7] text-gray-900 text-sm rounded-lg focus:ring-[#dad3b7] focus:border-[#dad3b7] block  p-2.5">
                  <option>Relevance</option>
                  <option> By name (asc)</option>
                  <option> By name (desc)</option>
                  <option>Relevance</option>
                  <option> By name (asc)</option>
                  <option> By name (desc)</option>
                </select>
              </div> */}
            </div>
            <div className="grid grid-cols-12 pb-5 gap-2 md:gap-3 -mr-3 -ml-3 ">
              <div className="col-span-12 md:col-span-3 px-3 me-5 ">
                <form className="plp-filters">
                  <div className="max-w-md mx-auto">
                    <div className="accordion mb-4 border-2 border-[#E3DBD1] rounded-[2.0625rem] bg-white">
                      <div className="accordion-item">
                        <div
                          className="accordion-header flex items-center justify-between font-normal text-lg leading-6 py-[1.25rem]  ps-6 pe-12 border-b"
                          onClick={() => {
                            setClick(!click);
                          }}
                        >
                          <p className="md:text-lg text-base font-semibold">
                            Products
                          </p>
                          <i className="fa-solid fa-angle-down cursor-pointer"></i>
                        </div>

                        <div
                          className={`accordion-content p-5 max-h-[159.921px] ${
                            click ? "block" : "hidden"
                          }`}
                        >
                          <div className="flex items-start mb-2">
                            <div className="flex items-center h-5">
                              <input
                                name="remote"
                                id="remember"
                                type="checkbox"
                                value=""
                                className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                                onChange={() => handleChange("remote")}
                              />
                            </div>
                            <label
                              htmlFor="remember"
                              className="ms-2 text-sm font-medium"
                            >
                              Remote Controllers
                            </label>
                          </div>
                          <div className="flex items-start mb-2">
                            <div className="flex items-center h-5">
                              <input
                                name="motors"
                                id="motors"
                                type="checkbox"
                                value=""
                                className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                                onChange={() => handleChange("motors")}
                              />
                            </div>
                            <label
                              htmlFor="motors"
                              className="ms-2 text-sm font-medium"
                            >
                              Motors
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-line relative -search">
                    <input
                      type="text"
                      id="first_name"
                      className="bg-gray-50 border relative border-gray-300  text-sm rounded-[20px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                      placeholder="Search"
                      value={searchTerm}
                      onChange={handleSearch}
                    />
                    <button
                      className="searchBtn  absolute right-2 bottom-[0.5rem] top-[0.5rem] cursor-pointer flex items-center"
                    >
                      <i className="fa-solid fa-search px-2 py-2 bg-[#2b403b] text-white rounded-full"></i>
                    </button>
                  </div>
                </form>
              </div>
              <div className="md:col-span-9 col-span-12 px-3 ">
                <div className="plp-products products grid gap-3 grid-col-1 md:grid-cols-2 lg:grid-cols-3">
                  {filteredData?.map((item: any, index: any) => {
                    const newIndex = index + 1;
                    return (
                      <Link
                        key={index}
                        to={`/product/viewProduct?id=${newIndex}`}
                      >
                        <div className="product relative h-full  border-[#dad3b7] m-auto  text-[#2b403b] pt-[3.125rem] pb-[1.5rem] px-[1rem] bg-white text-center rounded-[20px] border-2">
                          <div>
                            <div className="product-visual relative w-full h-48 mb-4 overflow-hidden">
                              <img
                                src={item?.image}
                                className="product-img h-full w-full object-contain"
                                alt="Chronic RTS"
                              />
                            </div>
                            <span className="product-title m-auto mb-4 min-h-[3.25rem] cursor-pointer">
                              {item?.name}
                            </span>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                  {/* <Link to={`/product/viewProduct?id=2`}>
                    <div className="product relative h-full  border-[#dad3b7] m-auto  text-[#2b403b] pt-[3.125rem] pb-[1.5rem] px-[1rem] bg-white text-center rounded-[20px] border-2">
                      <div>
                        <div className="product-visual relative w-full h-48 mb-4 overflow-hidden">
                          <img
                            src={require("../../images/product2.jpeg")}
                            className="product-img h-full w-full object-contain"
                            alt="Chronic RTS"
                          />
                        </div>
                        <span className="product-title m-auto mb-4 min-h-[3.25rem] cursor-pointer">
                          MODEL DEC-35
                        </span>
                      </div>
                    </div>
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}
