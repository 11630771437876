import image1 from "../images/product (12).jpeg";
import image2 from "../images/product4.jpeg";
import image3 from "../images/product2.jpeg";
import blind from "../images/blind.png";
import honeyComb from "../images/honeycomb.png";
import roman from "../images/roman.png";
import projection from "../images/projection.png";
import sheer from "../images/sheer.png";
import residental from "../images/residental.png";
import institution from "../images/institutation.png";
import parking from "../images/parking.png";
import hospital from "../images/hospital.png";
import hotel from "../images/hotel.png";
import mall from "../images/mall.png";
import center from "../images/center.png";
import warehouse from "../images/warehouse.png";
import industry from "../images/industry.png";
import assemble from "../images/assemble.png";
import load from "../images/largeload.png";
import technology from "../images/technology.png";
import running from "../images/running.png";
import remote from "../images/remote.png";
import silent from "../images/silent.png";
import certificate1 from "../images/certificate1.png";
import certificate2 from "../images/certificate2.png";
import certificate3 from "../images/certificate3.png";
import certificate4 from "../images/certificate4.png";
import certificate5 from "../images/certificate5.png";
import certificate6 from "../images/certificate6.png";
import certificate7 from "../images/certificate7.png";
import certificate8 from "../images/certificate8.png";
import certificate9 from "../images/certificate9.png";
import certificate10 from "../images/certificate10.png";
import moreImage1 from "../images/product (11).jpeg";
import moreImage2 from "../images/product (12).jpeg";
import moreImage3 from "../images/product (14).jpeg";
import moreImage4 from "../images/product (9).jpeg";
import moreImage5 from "../images/product (13).jpeg";
import moreImage6 from "../images/product2.jpeg";
export const data = [
  {
    type:"motors",
    name: "MODEL DEC-25",
    image: image1,
    content:
      "The smart way to automate your motorized systems. Set schedules easily with individual or group control. Simple, efficient, and hassle-free. Elevate your space effortlessly with our user-friendly device.",
    specification: {
      ratedTorque: "1.6 Nm",
      ratedSpeed: "25 RPM",
      powerSupplyVoltage: "100 V - 240 V AC",
      motorPower: "12W",
      ratedCurrent: "1 A",
      motorDiameter: "25 MM",
      runningTime: "No Limit",
      maxLoad: "7 Kg.",
      protectionLeavel: "Ip44",
    },
    application: [
      {
        type: "Moterized Roller Blinds",
        image: blind,
      },
      {
        type: "Moterized Honey Comb Blinds",
        image: honeyComb,
      },
      {
        type: "Moterized Roman Blinds",
        image: roman,
      },
      {
        type: "Moterized SProjection Screen",
        image: projection,
      },
      {
        type: "Moterized Sheer Blinds",
        image: sheer,
      },
    ],
    industries: [
      {
        type: "Residental",
        image: residental,
      },
      {
        type: "Institution",
        image: institution,
      },
      {
        type: "Public Parking",
        image: parking,
      },
      {
        type: "Hospital",
        image: hospital,
      },
      {
        type: "Hotel",
        image: hotel,
      },
      {
        type: "Mall",
        image: mall,
      },
      {
        type: "Business Center",
        image: center,
      },
      {
        type: "Warehouse",
        image: warehouse,
      },
      {
        type: "Industry",
        image: industry,
      },
    ],
    feature: [
      {
        type: "ASSEMBLED FAST",
        image: assemble,
      },
      {
        type: "LARGE LOAD",
        image: load,
      },
      {
        type: "HIGH TECHNOLOGY",
        image: technology,
      },
      {
        type: "SMOOTH RUNNING",
        image: running,
      },
      {
        type: "WIRELESS REMOTE",
        image: remote,
      },
      {
        type: "SUPER SILENT",
        image: silent,
      },
    ],
    details: {
      description:
        "The smart way to automate your motorized systems. Set schedules easily with individual or group control. Simple, efficient, and hassle-free. Elevate your space effortlessly with our user-friendly device.",
      dimention: {
        width: "440mm",
        height: "45 mm",
      },
    },
    certification: [
      certificate1,
      certificate2,
      certificate3,
      certificate4,
      certificate5,
      certificate6,
      certificate7,
      certificate8,
      certificate9,
      certificate10,
    ],
    benefits:[
      {
        title:"Smooth and Quiet Operation",
        description:"Experience a noise-free operation with the absence of brake noise during both pening and stopping moments."
      },
      {
        title:"Precision Gearbox for Accuracy",
        description:"Enjoy precise and accurate movements, thanks to our advanced gearbox technology."
      },
      {
        title:"Silent Motor Technology with Suspension",
        description:"Our motor is designed with suspension technology, ensuring not only silence but quietly below 35dB ."
      },
      {
        title:"Versatile Control Options",
        description:"The 3-wire motor comes equipped with an inbuilt receiver Control it effortlessly using a remote, smartphone app, or integrate it seamlessly with popular voice assistants such as Alexa and Google Assistant."
      },
      {
        title:"Sealed Transformer with Voltage Reduction",
        description:"Ensures safety and reliability with a sealed transformer and voltage reduction system."
      },
      {
        title:"Electronic Limit with Built-In Receiver",
        description:"Precision ensured through an electronic limit system and built-in receiver."
      },
      {
        title:"Double Hall Sensor for Precise Limit Control",
        description:" Unmatched precision using a double Hall sensor system for accurate limit control."
      },
    ],
    moreImage:[
      moreImage1,moreImage2,moreImage3
    ]
  },
  {
    type:"motors",
    name: "MODEL DEC-35",
    image: image2,
    content:
      "The smart way to automate your motorized systems. Set schedules easily with individual or group control. Simple, efficient, and hassle-free. Elevate your space effortlessly with our user-friendly device.",
    specification: {
      ratedTorque: "10 Nm",
      ratedSpeed: "17 RPM",
      powerSupplyVoltage: "220 V - 240 V AC",
      motorPower: "1230W",
      ratedCurrent: "0.55A",
      motorDiameter: "35 MM",
      runningTime: "≤4 Min",
      maxLoad: "20 Kg.",
      protectionLeavel: "Ip44",
    },
    application: [
      {
        type: "Moterized Roller Blinds",
        image: blind,
      },
      {
        type: "Moterized Honey Comb Blinds",
        image: honeyComb,
      },
      {
        type: "Moterized Roman Blinds",
        image: roman,
      },
      {
        type: "Moterized SProjection Screen",
        image: projection,
      },
      {
        type: "Moterized Sheer Blinds",
        image: sheer,
      },
    ],
    industries: [
      {
        type: "Residental",
        image: residental,
      },
      {
        type: "Institution",
        image: institution,
      },
      {
        type: "Public Parking",
        image: parking,
      },
      {
        type: "Hospital",
        image: hospital,
      },
      {
        type: "Hotel",
        image: hotel,
      },
      {
        type: "Mall",
        image: mall,
      },
      {
        type: "Business Center",
        image: center,
      },
      {
        type: "Warehouse",
        image: warehouse,
      },
      {
        type: "Industry",
        image: industry,
      },
    ],
    feature: [
      {
        type: "ASSEMBLED FAST",
        image: assemble,
      },
      {
        type: "LARGE LOAD",
        image: load,
      },
      {
        type: "HIGH TECHNOLOGY",
        image: technology,
      },
      {
        type: "SMOOTH RUNNING",
        image: running,
      },
      {
        type: "WIRELESS REMOTE",
        image: remote,
      },
      {
        type: "SUPER SILENT",
        image: silent,
      },
    ],
    details: {
      description:
        "The smart way to automate your motorized systems. Set schedules easily with individual or group control. Simple, efficient, and hassle-free. Elevate your space effortlessly with our user-friendly device.",
      dimention: {
        width: "440mm",
        height: "45 mm",
      },
    },
    certification: [
      certificate1,
      certificate2,
      certificate3,
      certificate4,
      certificate5,
      certificate6,
      certificate7,
      certificate8,
      certificate9,
      certificate10,
    ],
    benefits:[
      {
        title:"Smooth and Quiet Operation",
        description:"Experience a noise-free operation with the absence of brake noise during both pening and stopping moments."
      },
      {
        title:"Precision Gearbox for Accuracy",
        description:"Enjoy precise and accurate movements, thanks to our advanced gearbox technology."
      },
      {
        title:"Silent Motor Technology with Suspension",
        description:"Our motor is designed with suspension technology, ensuring not only silence but quietly below 35dB ."
      },
      {
        title:"Versatile Control Options",
        description:"The 3-wire motor comes equipped with an inbuilt receiver Control it effortlessly using a remote, smartphone app, or integrate it seamlessly with popular voice assistants such as Alexa and Google Assistant."
      },
      {
        title:"Sealed Transformer with Voltage Reduction",
        description:"Ensures safety and reliability with a sealed transformer and voltage reduction system."
      },
      {
        title:"Electronic Limit with Built-In Receiver",
        description:"Precision ensured through an electronic limit system and built-in receiver."
      },
      {
        title:"Double Hall Sensor for Precise Limit Control",
        description:" Unmatched precision using a double Hall sensor system for accurate limit control."
      },
    ],
    moreImage:[
      moreImage4,moreImage5,moreImage3
    ]
  },
  {
    type:"remote",
    name: "EMITTER MODEL DEC - 100",
    image: image3,
    content:
      "The smart way to automate your motorized systems. Set schedules easily with individual or group control. Simple, efficient, and hassle-free. Elevate your space effortlessly with our user-friendly device.",

    application: [
      {
        type: "Moterized Roller Blinds",
        image: blind,
      },
      {
        type: "Moterized Honey Comb Blinds",
        image: honeyComb,
      },
      {
        type: "Moterized Roman Blinds",
        image: roman,
      },
      {
        type: "Moterized SProjection Screen",
        image: projection,
      },
      {
        type: "Moterized Sheer Blinds",
        image: sheer,
      },
    ],
    industries: [
      {
        type: "Residental",
        image: residental,
      },
      {
        type: "Institution",
        image: institution,
      },
      {
        type: "Public Parking",
        image: parking,
      },
      {
        type: "Hospital",
        image: hospital,
      },
      {
        type: "Hotel",
        image: hotel,
      },
      {
        type: "Mall",
        image: mall,
      },
      {
        type: "Business Center",
        image: center,
      },
      {
        type: "Warehouse",
        image: warehouse,
      },
      {
        type: "Industry",
        image: industry,
      },
    ],
    feature: [
      {
        type: "ASSEMBLED FAST",
        image: assemble,
      },
      {
        type: "LARGE LOAD",
        image: load,
      },
      {
        type: "HIGH TECHNOLOGY",
        image: technology,
      },
      {
        type: "SMOOTH RUNNING",
        image: running,
      },
      {
        type: "WIRELESS REMOTE",
        image: remote,
      },
      {
        type: "SUPER SILENT",
        image: silent,
      },
    ],
    details: {
      description:
        "The smart way to automate your motorized systems. Set schedules easily with individual or group control. Simple, efficient, and hassle-free. Elevate your space effortlessly with our user-friendly device.",
      dimention: {
        width: "440mm",
        height: "45 mm",
      },
    },
    certification: [
      certificate1,
      certificate2,
      certificate3,
      certificate4,
      certificate5,
      certificate6,
      certificate7,
      certificate8,
      certificate9,
      certificate10,
    ],
    benefits:[
      {
        title:"Smooth and Quiet Operation",
        description:"Experience a noise-free operation with the absence of brake noise during both pening and stopping moments."
      },
      {
        title:"Precision Gearbox for Accuracy",
        description:"Enjoy precise and accurate movements, thanks to our advanced gearbox technology."
      },
      {
        title:"Silent Motor Technology with Suspension",
        description:"Our motor is designed with suspension technology, ensuring not only silence but quietly below 35dB ."
      },
      {
        title:"Versatile Control Options",
        description:"The 3-wire motor comes equipped with an inbuilt receiver Control it effortlessly using a remote, smartphone app, or integrate it seamlessly with popular voice assistants such as Alexa and Google Assistant."
      },
      {
        title:"Sealed Transformer with Voltage Reduction",
        description:"Ensures safety and reliability with a sealed transformer and voltage reduction system."
      },
      {
        title:"Electronic Limit with Built-In Receiver",
        description:"Precision ensured through an electronic limit system and built-in receiver."
      },
      {
        title:"Double Hall Sensor for Precise Limit Control",
        description:" Unmatched precision using a double Hall sensor system for accurate limit control."
      },
    ],
    moreImage:[
      moreImage6
    ]
  },
];
